import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

import {
    email,
    blog,
    instagram,
    github,
    githubMarmelab,
    linkedin,
} from '../../config';

import Layout from '../layout/Layout';
import Infos from '../components/Infos';
import GalleryTunisia from '../components/GalleryTunisia';
import GalleryCanada from '../components/GalleryCanada';

import '../assets/scss/home.scss';

const query = graphql`
    query {
        site {
            siteMetadata {
                title
                description
                keywords
                author
            }
        }
    }
`;

const HomePage = () => (
    <StaticQuery
        query={query}
        render={data => {
            const {
                title,
                description,
                keywords,
                author,
            } = data.site.siteMetadata;
            return (
                <Layout
                    id="home"
                    title={title}
                    description={description}
                    keywords={keywords}
                    author={author}
                >
                    <main>
                        <section id="infos" className="section">
                            <Infos currentPage="/" />
                        </section>
                        <section className="section">
                            <section className="section align-left">
                                <h3 className="section-title">
                                    Ma philosophie
                                </h3>
                                <p>
                                    Développeur web depuis plus de 5 ans, je
                                    travaille pour et avec des entreprises qui
                                    ont du sens. À l&apos;heure du tout
                                    numérique, je suis convaincu que
                                    l&apos;accessibilité et l&apos;impact social
                                    et environnemental doivent être au centre
                                    des projets de demain.
                                </p>
                                <p>
                                    Je suis également persuadé que le partage
                                    des connaissances est une valeur
                                    essentielle. C&apos;est pourquoi
                                    j&apos;essaye de contribuer à mon échelle,
                                    en participant à la création et à la
                                    maintenance de logiciels libres.
                                </p>
                            </section>
                            <section className="section align-left">
                                <h3 className="section-title">
                                    Ce que je fais / Ce que j&apos;aime faire
                                </h3>
                                <p>
                                    Mes différentes expériences professionnelles
                                    m&apos;ont amenées à couvrir toutes les
                                    étapes d&apos;un projet web moderne, de sa
                                    création à son déploiement en production.
                                </p>
                                <p>
                                    Professionnellement, j&apos;apprécie :
                                    <ul>
                                        <li>
                                            Communiquer régulièrement et de
                                            manière honnête (c&apos;est la clef
                                            de la réussite d&apos;un projet)
                                        </li>
                                        <li>
                                            Développer en utilisant le langage
                                            JavaScript et ses dérivées : React /
                                            NodeJS / Express
                                        </li>
                                        <li>
                                            Réfléchir sur des problèmes
                                            d&apos;UX ( expérience utilisateur ,
                                            design)
                                        </li>
                                        <li>
                                            Convertir un problème en une
                                            solution fonctionnelle et technique
                                        </li>
                                    </ul>
                                </p>
                                <p>
                                    Plus personnellement, j&apos;aime aussi :
                                    <ul>
                                        <li>Partager mon travail</li>
                                        <li>
                                            Capturer des paysages et des lieux
                                            en photos
                                        </li>
                                        <li>
                                            Voyager, souvent, en France et dans
                                            le monde (en évitant au maximum de
                                            prendre l&nbsp;avion)
                                        </li>
                                        <li>
                                            Développer mes sites et projets
                                            perso
                                        </li>
                                    </ul>
                                </p>
                            </section>
                            <section className="section align-left">
                                <h3 className="section-title">
                                    Plus d&apos;infos
                                </h3>
                                <p>
                                    Je suis ouvert à des missions de freelance.
                                    Si vous êtes intéressé, contactez-moi via
                                    mon profil{' '}
                                    <a
                                        href={linkedin.link}
                                        aria-label={linkedin.name}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        LinkedIn
                                    </a>{' '}
                                    . Vous pouvez aussi retrouver quelques-uns
                                    de mes projets sur{' '}
                                    <a
                                        href={github.link}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        ma page Github : {github.name}
                                    </a>{' '}
                                    ou sur{' '}
                                    <a
                                        href={githubMarmelab.link}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        la page Github de mon entreprise
                                        actuelle : {githubMarmelab.name}
                                    </a>
                                    . Vous pouvez aussi me contacter par email :{' '}
                                    <a
                                        href={`mailto:${email}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {email}
                                    </a>{' '}
                                    🙂.
                                </p>
                            </section>
                        </section>
                        <section className="section">
                            <section className="section align-left">
                                <p>
                                    J&apos;ai posté ci-dessous quelques photos
                                    de mes derniers voyages. Je publie également
                                    des carnets de route sur mon site{' '}
                                    <a
                                        href={blog.link}
                                        aria-label={blog.name}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        Prendre La Route
                                    </a>
                                    . Le reste se passe sur{' '}
                                    <a
                                        href={instagram.link}
                                        aria-label={instagram.name}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        Instagram
                                    </a>
                                    .
                                </p>
                            </section>
                            <section id="showcase-tunisia" className="section">
                                <h3 className="section-title">
                                    Tunisie, Février 2020
                                </h3>
                                <GalleryTunisia />
                            </section>
                            <section id="showcase-canada" className="section">
                                <h3 className="section-title">
                                    Canada, Juin & Juillet 2019
                                </h3>
                                <GalleryCanada />
                            </section>
                        </section>
                    </main>
                </Layout>
            );
        }}
    />
);

export default HomePage;
