import React from 'react';

const year = new Date().getFullYear();
const copyright = `© Adrien Amoros | 2015 - ${year}`;

const Footer = () => (
    <footer id="footer">
        <div className="inner">
            <p className="copyright">{copyright}</p>
        </div>
    </footer>
);

Footer.propTypes = {};

Footer.defaultProps = {};

export default Footer;
