import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import { instagram, github } from '../../config';
import Avatar from '../components/Avatar';

const Infos = ({ currentPage = '/' }) => {
    return (
        <>
            <header className="title">
                {currentPage === '/' ? (
                    <>
                        <Avatar />
                        <h1>Adrien Amoros</h1>
                    </>
                ) : (
                    <Link to="/">
                        <Avatar />
                        <h1>Adrien Amoros</h1>
                    </Link>
                )}
            </header>
            <p className="subtitle">
                <span>
                    <a
                        href={github.link}
                        aria-label={github.name}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Développeur Web
                    </a>
                </span>
                <span>&</span>
                <span>
                    <a
                        href={instagram.link}
                        aria-label={instagram.name}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Passionné de voyage
                    </a>
                </span>
            </p>
        </>
    );
};

Infos.propTypes = {
    currentPage: PropTypes.string,
};

export default Infos;
