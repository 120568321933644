import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

import '../assets/scss/main.scss';

import Footer from '../components/Footer';

const Layout = ({ id, children, title, description, keywords, author }) => (
    <>
        <Helmet
            title={title}
            meta={[
                {
                    name: 'description',
                    content: description,
                },
                {
                    name: 'keywords',
                    content: keywords.join(','),
                },
                {
                    name: 'author',
                    content: author,
                },

                {
                    name: 'google-site-verification',
                    content: '2E93TdwKJ6auHzXGKLgENRzaV0rv4RAUHDTqwgj6KLE',
                },
            ]}
        >
            <html lang="fr" />
        </Helmet>
        <div id={id}>
            {children}
            <Footer />
        </div>
    </>
);

Layout.propTypes = {
    id: PropTypes.string,
    children: PropTypes.node.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    keywords: PropTypes.arrayOf(PropTypes.string),
    author: PropTypes.string,
};

Layout.defaultProps = {
    description: '',
    keywords: [],
    author: '',
};

export default Layout;
