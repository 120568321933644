const PLR_LINK = 'https://www.prendre-la-route.fr';
const INSTAGRAM_LINK = 'https://www.instagram.com/luwangel';
const GITHUB_LINK = 'https://www.github.com/luwangel';
const GITHUB__MARMELAB_LINK = 'https://www.github.com/marmelab';
const LINKEDIN_LINK = 'https://www.linkedin.com/in/adrien-amoros';

const EMAIL = 'adrien.amoros@gmail.com';

module.exports = {
    siteTitle: 'Adrien Amoros | Développeur Web & Photographe Amateur',
    siteDescription:
        'Développeur web et photographe amateur, spécialisé dans le voyage.',
    siteKeywords: [
        'photographe',
        'photographe voyage',
        'développeur',
        'développeur web',
        'blog',
    ],
    siteAuthor: {
        name: 'Adrien Amoros',
        contact: EMAIL,
        link: 'https://www.adrienamoros.fr',
    },
    manifestName: 'Adrien Amoros',
    manifestShortName: 'AA', // max 12 characters
    manifestStartUrl: '/',
    manifestBackgroundColor: '#000000',
    manifestThemeColor: '#000000',
    manifestDisplay: 'standalone',
    manifestIcon: 'src/assets/images/icon-512x512.png',
    pathPrefix: ``,
    // Social
    email: EMAIL,
    blog: {
        name: 'Prendre La Route',
        link: PLR_LINK,
    },
    instagram: {
        name: '@Luwangel',
        link: INSTAGRAM_LINK,
    },
    github: {
        name: 'Luwangel',
        link: GITHUB_LINK,
    },
    githubMarmelab: {
        name: 'marmelab',
        link: GITHUB__MARMELAB_LINK,
    },
    linkedin: {
        name: 'Adrien Amoros',
        link: LINKEDIN_LINK,
    },
    socialLinks: [
        {
            icon: 'fa-instagram',
            name: 'Instagram',
            url: INSTAGRAM_LINK,
        },
        {
            icon: 'fa-github',
            name: 'Github',
            url: GITHUB_LINK,
        },
        {
            icon: 'fa-envelope',
            name: 'Email',
            url: `mailto:${EMAIL}`,
        },
    ],
    // Google Analytics
    trackingId: 'UA-158648423-1',
};
