import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

export const query = graphql`
    query {
        file(relativePath: { eq: "avatar.jpg" }) {
            childImageSharp {
                fixed(width: 125, height: 125) {
                    ...GatsbyImageSharpFixed
                }
            }
        }
    }
`;

const Avatar = () => (
    <StaticQuery
        query={query}
        render={data => {
            const image = data.file.childImageSharp.fixed;
            return (
                <div className="image avatar">
                    <Img
                        fixed={image}
                        alt="Portrait au pied d'une cascade sur l'île de la Réunion"
                    />
                </div>
            );
        }}
    />
);

export default Avatar;
