import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import GalleryWithPreview from '../ui/GalleryWithPreview';

const compare = (a, b) => (a > b ? 1 : a < b ? -1 : 0);

const query = graphql`
    query {
        allFile(
            filter: {
                extension: { regex: "/(jpg)|(png)|(jpeg)/" }
                relativeDirectory: { eq: "tunisia" }
            }
        ) {
            edges {
                node {
                    base
                    childImageSharp {
                        fluid {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        }
    }
`;

const GalleryTunisia = () => {
    const data = useStaticQuery(query);

    const images = data.allFile.edges
        .map(edge => {
            const {
                aspectRatio,
                base64,
                src,
                srcSet,
                sizes,
            } = edge.node.childImageSharp.fluid;

            return {
                alt: edge.node.base,
                base64,
                src,
                srcSet,
                sizes,
                width: aspectRatio,
                height: 1,
            };
        })
        .sort((imageA, imageB) => compare(imageA.alt, imageB.alt));

    return <GalleryWithPreview images={images} />;
};

export default GalleryTunisia;
