import React, { useState, useCallback } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import Gallery from 'react-photo-gallery';
import Carousel, { Modal, ModalGateway } from 'react-images';
import Img from 'gatsby-image';

export const FooterCount = props => {
    // https://github.com/jossmac/react-images/blob/a74c4bc73fc5492186c50ba01f656bfd3c40bb52/src/components/Footer.js
    // Forked to translate the counter
    const { currentIndex, getStyles, isFullscreen, isModal, views } = props; // eslint-disable-line react/prop-types

    const state = { isFullscreen, isModal };
    const activeView = currentIndex + 1;
    const totalViews = views.length; // eslint-disable-line react/prop-types

    if (!activeView || !totalViews) return null;

    return (
        <span
            css={getStyles('footerCount', props)}
            className={classnames('footer__count', state)}
        >
            {activeView} sur {totalViews}
        </span>
    );
};

const GalleryWithPreview = ({ images }) => {
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = useCallback((_, { index }) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    }, []);

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    return (
        <div>
            <Gallery photos={images} onClick={openLightbox} />
            <ModalGateway>
                {viewerIsOpen ? (
                    <Modal onClose={closeLightbox}>
                        <Carousel
                            currentIndex={currentImage}
                            views={images.map(image => {
                                return {
                                    ...image,
                                    srcset: image.srcSet,
                                    caption: image.title,
                                };
                            })}
                            renderImage={Img}
                            formatters={{
                                getAltText: ({ data }) => data.caption,
                                getNextLabel: () =>
                                    'Suivant (flèche de droite)',
                                getPrevLabel: () =>
                                    'Précédent (flèche de gauche)',
                                getNextTitle: () => 'Suivant',
                                getPrevTitle: () => 'Précédent',
                                getCloseLabel: () => 'Fermer',
                                getFullscreenLabel: ({ isFullscreen }) =>
                                    isFullscreen
                                        ? 'Quitter le mode plein écran'
                                        : 'Ouvrir le mode plein écran',
                            }}
                            components={{ FooterCount }}
                        />
                    </Modal>
                ) : null}
            </ModalGateway>
        </div>
    );
};

GalleryWithPreview.propTypes = {
    images: PropTypes.arrayOf(PropTypes.object),
};

export default GalleryWithPreview;
